import { Controller } from "stimulus"
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ "form", "card", "errors", "submitButton", "firstName", "lastName" ]
  static values = {
    publicKey: String
  }

  connect() {
    this.stripe = Stripe(this.publicKeyValue)
    const elements = this.stripe.elements()

    this.card = elements.create("card", {
      style: {
        base: {
          fontSize: '16px',
          lineHeight: '24px',
          color: 'rgb(55, 65, 81)',
          '::placeholder': {
            color: 'rgb(209, 213, 219)'
          },
        }
      }
    })
    this.card.mount(this.cardTarget)
  }

  paymentMethodStripeIdHandler(payment_method_stripe_id) {
    const hiddenInput = document.createElement("input")

    hiddenInput.setAttribute("type", "hidden")
    hiddenInput.setAttribute("name", "payment_method_stripe_id")
    hiddenInput.setAttribute("value", payment_method_stripe_id)
    this.formTarget.appendChild(hiddenInput)

    Rails.fire(this.formTarget, 'submit')
  }

  async submitForm(event) {
    event.preventDefault()

    this.hideErrors()

    const cardholderName = `${this.firstNameTarget.value} ${this.lastNameTarget.value}`
    const setupForm = this.formTarget;
    const clientSecret = setupForm.dataset.secret;

    const {setupIntent, error} = await this.stripe.confirmCardSetup(
      clientSecret,
      {
        payment_method: {
          card: this.card,
          billing_details: {
            name: cardholderName.value,
          },
        },
      }
    );

    if (error) {
      this.errorsTarget.textContent = error.message
      this.showErrors()

      this.submitButtonTarget.removeAttribute('disabled')
      this.submitButtonTarget.textContent = "Add payment method"

    } else {
      if (setupIntent.status === 'succeeded') {
        this.paymentMethodStripeIdHandler(setupIntent.payment_method)
      }
    }
  }

  hideErrors() {
    this.errorsTarget.classList.add('hidden')
  }

  showErrors() {
    this.errorsTarget.classList.remove('hidden')
  }
}
